import Swiper from 'swiper'

document.addEventListener('DOMContentLoaded', () => {

	document.querySelectorAll('.js-contents').forEach(contents => {
		new Swiper(contents.querySelector('.swiper'), {
			freeMode: true,
			grabCursor: true,
			slidesPerView: 'auto',
			spaceBetween: 30
		})
	})

})
