import { toggle } from 'slide-element'

document.addEventListener('DOMContentLoaded', () => {

	// Toggle product filter groups on click
	document.querySelectorAll('.js-product-filter').forEach(productFilter => {
		const button = productFilter.querySelector('.js-product-filter-button')
		const filters = productFilter.querySelector('.js-product-filter-filters')

		button.addEventListener('click', () => {
			productFilter.classList.toggle('is-open')
			toggle(filters)
		})
	})

	// Toggle filter links active state on click
	document.querySelectorAll('.js-product-filter-link').forEach(link => {
		link.addEventListener('click', () => {
			link.classList.toggle('is-active')
		})
	})

})
