import { toggle, up } from 'slide-element'

function closeAllDropdowns(except) {
	document.querySelectorAll('.js-dropdown.is-open').forEach(languageDropdown => {
		if(languageDropdown === except) {
			return
		}

		languageDropdown.classList.remove('is-open')
		up(languageDropdown.querySelector('.js-dropdown-options'))
	})
}

document.addEventListener('DOMContentLoaded', () => {

	document.querySelectorAll('.js-dropdown').forEach(languageDropdown => {
		const dropdown = languageDropdown.querySelector('.js-dropdown-options')

		const toggleDropdown = () => {
			toggle(dropdown)

			// If not already open, instantly add is-open
			if(!languageDropdown.classList.contains('is-open')) {
				languageDropdown.classList.add('is-open')
			}
			// Wait until animation finishes to remove is-open class
			else {
				setTimeout(() => {
					languageDropdown.classList.remove('is-open')
				}, 250)
			}
		}

		// Toggle dropdown
		languageDropdown.querySelector('.js-dropdown-toggle').addEventListener('click', event => {
			event.stopPropagation()
			event.preventDefault()

			// Close all dropdowns other than this one
			closeAllDropdowns(languageDropdown)

			toggleDropdown()
		})

		// Set option when clicked
		languageDropdown.querySelectorAll('.js-dropdown-option').forEach(option => {
			option.addEventListener('click', event => {
				// Don't stop links from changing page
				if(!option.hasAttribute('href')) {
					event.stopPropagation()
					event.preventDefault()
				}

				// Horrible hack to remove auto-injected element that breaks the custom category dropdown for WP Store Locator
				document.querySelectorAll('.js-wpsl-fake-dropdown .wpsl-selected-item').forEach(element => element.remove())

				// Set value in hidden select for forms
				const select = languageDropdown.querySelector('.js-dropdown-select')
				select.value = option.dataset.value
				select.dispatchEvent(new Event('change'))

				// Set dropdown label
				const label = languageDropdown.querySelector('.js-dropdown-label')

				// Set label back to default if default option chosen
				// Also set data-value for WP Store Locator
				if(option.dataset.value === '0') {
					label.innerHTML = label.dataset.originalLabel
					label.dataset.value = '0'
				}
				// Otherwise use selected option
				else {
					label.innerHTML = option.innerHTML
					label.dataset.value = option.dataset.value
				}

				// Close dropdown
				toggleDropdown()
			})
		})
	})

	// Close all dropdowns on click outside
	document.addEventListener('click', () => {
		closeAllDropdowns()
	})

})
