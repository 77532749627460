import Swiper from 'swiper'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'

Swiper.use([Autoplay, Navigation, Pagination])

document.addEventListener('DOMContentLoaded', () => {

	document.querySelectorAll('.js-swiper').forEach(swiper => {
		const largeSlides = swiper.dataset.slides || 4
		const mediumSlides = largeSlides - 1

		const options = {
			autoplay: false,
			freeMode: false,
			grabCursor: true,
			loop: false,
			navigation: {
				nextEl: swiper.querySelector('.swiper-button-next'),
				prevEl: swiper.querySelector('.swiper-button-prev')
			},
			pagination: {
				el: swiper.querySelector('.swiper-pagination'),
				type: 'fraction'
			},
			watchOverflow: true,
			breakpoints: {
				0: {
					slidesOffsetAfter: 30,
					slidesOffsetBefore: 30,
					slidesPerGroup: 1,
					slidesPerView: 1.5,
					spaceBetween: 40
				},
				768: {
					slidesOffsetAfter: 30,
					slidesOffsetBefore: 30,
					slidesPerGroup: 2,
					slidesPerView: 2.75,
					spaceBetween: 60
				},
				992: {
					slidesOffsetAfter: 0,
					slidesOffsetBefore: 0,
					slidesPerGroup: mediumSlides,
					slidesPerView: mediumSlides,
					spaceBetween: 60
				},
				1200: {
					slidesOffsetAfter: 0,
					slidesOffsetBefore: 0,
					slidesPerGroup: largeSlides,
					slidesPerView: largeSlides,
					spaceBetween: 60
				}
			}
		}

		if(swiper.dataset.type == 'logo') {
			options.autoplay = {
				delay: 1,
				disableOnInteraction: false,
				pauseOnMouseEnter: true
			}
			options.freeMode = {
				enabled: true,
				momentum: false
			}
			options.loop = true
			options.speed = 4000

			for(const breakpoint in options.breakpoints) {
				if(breakpoint == 0) {
					continue
				}
				options.breakpoints[breakpoint].slidesPerGroup = 1
				options.breakpoints[breakpoint].spaceBetween = 130
			}
		}

		new Swiper(swiper.querySelector('.swiper'), options)
	})

})
