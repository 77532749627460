import hoverintent from 'hoverintent'
import { down } from 'slide-element'

// Open given megamenu
function openMegamenu(megamenu) {
	megamenu.classList.add('is-open')
	down(megamenu)

	// Also fix header so it stays visible when scrolling and activate underlay
	document.getElementById('js-header').classList.add('is-fixed', 'has-underlay')
}

// Close all megamenus
function closeMegamenus() {
	document.querySelectorAll('.js-megamenu-open.has-open-megamenu').forEach(megamenuOpen => {
		megamenuOpen.classList.remove('has-open-megamenu')
	})

	document.querySelectorAll('.js-megamenu.is-open').forEach(megamenu => {
		megamenu.classList.remove('is-open')
		megamenu.style.display = 'none'
	})

	// Restore normal header behaviour when at top of page
	if(window.scrollY === 0) {
		document.getElementById('js-header').classList.remove('is-fixed')
	}
}

function removeHeaderUnderlay() {
	document.getElementById('js-header').classList.remove('has-underlay')
}

document.addEventListener('DOMContentLoaded', () => {

	// Open megamenu when button is hovered
	document.querySelectorAll('.js-menu-item').forEach(menuItem => {
		hoverintent(menuItem,
			() => {
				// If menu button has no megamenu, close all
				if(!menuItem.classList.contains('js-megamenu-open')) {
					closeMegamenus()
					removeHeaderUnderlay()
					return
				}

				const megamenuID = menuItem.dataset.megamenu
				const megamenuToOpen = document.querySelector(`.js-megamenu[data-megamenu="${megamenuID}"]`)

				// If megamenu to open is already open, do nothing
				if(megamenuToOpen.classList.contains('is-open')) {
					return
				}

				// Close any open megamenus before opening correct one
				closeMegamenus()

				// Open megamenu
				openMegamenu(megamenuToOpen)
				menuItem.classList.add('has-open-megamenu')
			},
			() => null
		)
	})

	// Close megamenu on mouseleave
	document.querySelectorAll('.js-megamenu').forEach(megamenu => {
		megamenu.addEventListener('mouseleave', event => {
			// Don't remove header underlay if opening a new megamenu
			if(event.relatedTarget && !event.relatedTarget.classList.contains('js-megamenu-open')) {
				removeHeaderUnderlay()
			}

			// Close megamenus if mouse didn't enter button to open currently open megamenu
			if(event.relatedTarget && !event.relatedTarget.classList.contains('has-open-megamenu')) {
				closeMegamenus()
			}
		})
	})

})
