document.addEventListener('DOMContentLoaded', () => {

	// Add focus classes to CF7 forms for floating labels
	document.querySelectorAll('.js-cf7').forEach(form => {
		const fields = form.querySelectorAll('input[type="text"], input[type="email"], textarea')

		fields.forEach(field => {
			field.addEventListener('focus', event => {
				const label = event.currentTarget.parentElement.nextElementSibling
				label.classList.add('is-focused')
			})

			field.addEventListener('blur', event => {
				const label = event.currentTarget.parentElement.nextElementSibling
				label.classList.remove('is-focused')

				if(event.currentTarget.value) {
					label.classList.add('is-populated')
				}
				else {
					label.classList.remove('is-populated')
				}
			})
		})
	})

	// Submit WP Store Locator form when any fields change
	document.querySelectorAll('.js-wp-store-locator').forEach(wpsl => {
		const button = wpsl.querySelector('#wpsl-search-btn')

		wpsl.querySelectorAll('input, select').forEach(field => {
			field.addEventListener('change', () => {
				button.click()
			})
		})
	})

})
