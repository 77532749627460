function isMegaMenuOpen() {
	return document.querySelectorAll('.js-megamenu.is-open').length > 0
}

document.addEventListener('DOMContentLoaded', () => {

	const header = document.getElementById('js-header')

	let lastScroll = 0

	const timeouts = []

	window.addEventListener('scroll', () => {
		// When scrolling down, hide header off-screen
		if(window.scrollY > lastScroll && window.scrollY > header.offsetHeight) {
			if(!header.classList.contains('is-hidden') && !isMegaMenuOpen()) {
				// Delay hide when header is already fixed
				if(header.classList.contains('is-fixed')) {
					const timeout = setTimeout(() => {
						header.classList.add('is-hidden')
					}, 5)
					timeouts.push(timeout)
				}
				else {
					header.classList.add('is-hidden')
				}
			}
		}
		// When scrolling up, reveal header
		else {
			// Clear all timeouts
			timeouts.forEach(timeout => {
				clearTimeout(timeout)
			})
			timeouts.length = 0

			header.classList.remove('is-hidden')
		}

		// When below header, fix to top of screen after hiding to prevent transition flash
		if(window.scrollY > header.offsetHeight) {
			header.classList.add('is-fixed')
		}

		// Unfix header at top of page
		if(window.scrollY === 0 && !isMegaMenuOpen()) {
			header.classList.remove('is-fixed')
		}

		lastScroll = window.scrollY
	})

})
