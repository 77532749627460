// Set region drawer to slide from top on desktop and from side on mobile
function updateRegionDrawerPosition(isDesktop) {
	const regionDrawer = document.getElementById('region-drawer')

	if(isDesktop) {
		regionDrawer.classList.remove('offcanvas-end')
		regionDrawer.classList.add('offcanvas-top')
	}
	else {
		regionDrawer.classList.remove('offcanvas-top')
		regionDrawer.classList.add('offcanvas-end')
	}
}

document.addEventListener('DOMContentLoaded', () => {

	const desktopMediaQuery = matchMedia('(min-width: 992px)')

	// Update region drawer position on page load
	updateRegionDrawerPosition(desktopMediaQuery.matches)

	// Update position when media query changes
	desktopMediaQuery.addEventListener('change', event => {
		updateRegionDrawerPosition(event.matches)
	})

})
