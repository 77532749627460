import { Loader } from '@googlemaps/js-api-loader'

async function init() {
	// Load Google Maps API
	const loader = new Loader({
		apiKey: 'AIzaSyCgSeORZxhvYT7WsG_6Qxgyo_K0a1fgaeU'
	})

	await loader.load()

	// Build map of locations
	if(document.getElementById('js-contact-map')) {
		const mapStyles = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]

		// Get addresses
		const addresses = []
		document.querySelectorAll('.js-contact-address').forEach(address => {
			addresses.push({
				lat: address.dataset.lat,
				lng: address.dataset.lng,
				zoom: parseInt(address.dataset.zoom, 10)
			})
		})

		const map = new google.maps.Map(document.getElementById('js-contact-map'), {
			center: {
				lat: parseFloat(addresses[0].lat),
				lng: parseFloat(addresses[0].lng)
			},
			styles: mapStyles,
			zoom: addresses[0].zoom
		})

		// Create empty bounds for setting map viewport
		const bounds = new google.maps.LatLngBounds()

		addresses.forEach(address => {
			if(!address.lat || !address.lng) {
				return
			}

			const marker = new google.maps.Marker({
				clickable: false,
				icon: {
					url: '/app/themes/audio-technica-distribution/assets/images/map_pin.png',
					scaledSize: new google.maps.Size(50, 57)
				},
				position: {
					lat: parseFloat(address.lat),
					lng: parseFloat(address.lng)
				},
				map: map
			})

			// Extend bounds to include every marker
			bounds.extend(marker.position)
		})

		// Set map viewport to include all markers if more than one
		if(addresses.length > 1) {
			map.fitBounds(bounds)
		}
	}
}

document.addEventListener('DOMContentLoaded', () => {
	init()
})
