document.addEventListener('DOMContentLoaded', () => {

	const search = document.getElementById('js-search')

	// Create Bootstrap backdrop to put behind header
	const backdrop = document.createElement('div')
	backdrop.classList = 'offcanvas-backdrop fade show'
	backdrop.addEventListener('click', () => closeSearch())

	const header = document.getElementById('js-header')

	// Open search, add backdrop and ensure header appears above
	const openSearch = () => {
		search.classList.add('is-open')
		document.body.appendChild(backdrop)
		header.style.zIndex = '1041'
	}

	// Close search, remove backdrop and reset header
	const closeSearch = () => {
		search.classList.remove('is-open')
		backdrop.remove()
		header.style.zIndex = null
	}

	// Open search buttons
	document.querySelectorAll('.js-search-button-open').forEach(openSearchButton => {
		openSearchButton.addEventListener('click', () => openSearch())
	})

	// Close search buttons
	document.querySelectorAll('.js-search-button-close').forEach(closeSearchButton => {
		closeSearchButton.addEventListener('click', () => closeSearch())
	})

	const searchField = search.querySelector('#js-search-field')

	// Focus search field when opened
	search.addEventListener('transitionend', () => {
		if(search.classList.contains('is-open')) {
			searchField.focus()
		}
	})

	// Trigger keyup when search field is focused to bring back SearchWP
	searchField.addEventListener('focus', () => {
		searchField.dispatchEvent(new Event('keyup'))
	})

	// Disable Enter key in search field
	searchField.addEventListener('keydown', event => {
		if(event.key == 'Enter') {
			event.preventDefault()
		}
	})

	// Close search field on Esc key
	window.addEventListener('keydown', event => {
		if(event.key == 'Escape') {
			searchField.blur()
			closeSearch()

			// Ensure SearchWP is closed
			const searchwp = document.querySelector('.searchwp-live-search-results')
			searchwp.setAttribute('aria-expanded', 'false')
			searchwp.classList.remove('searchwp-live-search-results-showing')
		}
	})

})
